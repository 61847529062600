type ErrorCode = "MISSING_DRIVER" | "OPEN_ERROR" | "PERMISSION_ERROR";

export default class PrinterError extends Error {
  code: ErrorCode;

  cause: unknown;

  constructor(code: ErrorCode, options?: { cause: unknown }) {
    super(`Error on PrinterProvider with code "${code}"`);
    this.code = code;
    this.cause = options?.cause;
  }
}
