import { useEffect } from "react";

export default function useVisibilityChange(
  callback: (visibility: boolean) => void,
) {
  useEffect(() => {
    const handleVisibilityChange = () => {
      callback(document.visibilityState === "visible");
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [callback]);
}
