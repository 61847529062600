"use client";

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useRef,
  type PropsWithChildren,
} from "react";
import { useTranslations } from "next-intl";
import useLocalStorageState from "use-local-storage-state";

import logger from "@offline/logger/logger";

import isWindows from "~/helpers/isWindows";
import { type Order } from "~/types";
import { useStoreInfo } from "../StoreInfoProvider";
import {
  findDevice,
  getDeviceId,
  isBrowserSupported,
  isOSSupported,
  requestUSBDevice,
} from "./helpers";
import OrderTicketGenerator from "./OrderTicketGenerator";
import Printer from "./Printer";
import PrinterError from "./PrinterError";
import {
  DeviceClassCode,
  type PrinterSettings,
  type PrinterSetupResult,
  type TicketType,
} from "./types";
import useInitialDevice from "./useInitialDevice";
import useUSBEvents from "./useUSBEvents";
import useVisibilityChange from "./useVisibilityChange";

interface PrinterState {
  device?: USBDevice;
  isReady: boolean;
  error?: PrinterError;
}

interface PrinterContext extends PrinterState {
  setup: () => Promise<PrinterSetupResult>;
  forget: () => Promise<void>;
  printTicket: (
    type: TicketType,
    order: Order,
    options?: { isTestTicket?: boolean },
  ) => Promise<void>;
  saveSettings: (settings: PrinterSettings) => void;
  osNotSupported: boolean;
  browserNotSupported: boolean;
  settings: PrinterSettings;
}

interface PrinterLocalState {
  id?: string;
  settings: PrinterSettings;
}

type PrinterAction =
  | {
      type: "permission-granted";
      device?: USBDevice;
    }
  | {
      type: "permission-denied";
      error?: PrinterError;
    }
  | {
      type: "opened";
      device: USBDevice;
    }
  | {
      type: "open-failed";
      error?: PrinterError;
      device: USBDevice;
    }
  | {
      type: "forget";
    }
  | {
      type: "set-unsupported-os";
    }
  | {
      type: "set-unsupported-browser";
    }
  | {
      type: "disconnect";
    }
  | {
      type: "forget-error";
    };

const PrinterContext = createContext<PrinterContext | undefined>(undefined);

const browserNotSupported = !isBrowserSupported();
const osNotSupported = !isOSSupported();

const defaultPrinterSettings: PrinterSettings = {
  allowSpecialChars: true,
  allowStyles: true,
  selectedColumns: 32,
};

const defaultPrinterState: PrinterState = {
  isReady: false,
  error: undefined,
  device: undefined,
};

const printerReducer = (state: PrinterState, action: PrinterAction) => {
  switch (action.type) {
    case "permission-granted":
      return {
        ...state,
        error: undefined,
        device: action.device,
      };

    case "permission-denied":
      return {
        ...state,
        error: action.error,
      };

    case "opened":
      return {
        ...state,
        isReady: true,
        error: undefined,
        device: action.device,
      };

    case "open-failed":
      return {
        ...state,
        isReady: false,
        error: action.error,
        device: action.device,
      };
    case "forget":
      return defaultPrinterState;
    case "disconnect":
      return {
        ...state,
        error: undefined,
        isReady: false,
      };
    case "forget-error":
    default:
      return state;
  }
};

function PrinterProvider({ children }: Readonly<PropsWithChildren>) {
  const storeInfo = useStoreInfo();
  const ticketTranslator = useTranslations("providers.printer-provider.ticket");
  const isPrintingRef = useRef(false);

  const [printerLocalState, setPrinterLocalState] =
    useLocalStorageState<PrinterLocalState>("selected-printer", {
      defaultValue: { settings: defaultPrinterSettings },
    });

  const [printerState, dispatch] = useReducer(
    printerReducer,
    defaultPrinterState,
  );

  const { settings } = printerLocalState;

  useInitialDevice(printerLocalState?.id, (device: USBDevice) => {
    initDevice(device);
  });

  useVisibilityChange(async (visible) => {
    if (!printerLocalState.id || !visible) {
      return;
    }

    const device = await findDevice(printerLocalState.id);

    if (!device) {
      return;
    }

    // Re-initialize device when window becomes visible in case the reference changed
    initDevice(device);
  });

  const initDevice = useCallback(
    async (device: USBDevice): Promise<PrinterSetupResult> => {
      setPrinterLocalState({ ...printerLocalState, id: getDeviceId(device) });

      dispatch({ type: "permission-granted", device });

      try {
        await device.open();
        dispatch({ type: "opened", device });

        return { status: "success", device };
      } catch (err) {
        let error: PrinterError | undefined;
        if (
          isWindows() &&
          err instanceof DOMException &&
          "SecurityError" === err.name
        ) {
          error = new PrinterError("MISSING_DRIVER");
        } else {
          error = new PrinterError("OPEN_ERROR", { cause: err });
        }

        dispatch({ type: "open-failed", error, device });

        logger.error(err);

        return { status: "failed", error };
      }
    },
    [printerLocalState, setPrinterLocalState],
  );

  const requestDevice = useCallback(async () => {
    let result: PrinterSetupResult;

    try {
      const device = await requestUSBDevice(DeviceClassCode.PRINTER);
      result = await initDevice(device);
    } catch (err) {
      // No device selected
      if (err instanceof Error && err.name === "NotFoundError") {
        result = { status: "cancelled" };
      } else {
        logger.error(err);
        const error = new PrinterError("PERMISSION_ERROR", { cause: err });
        dispatch({ type: "permission-denied", error });

        result = { status: "failed", error };
      }
    }

    return result;
  }, [initDevice]);

  useUSBEvents({
    currentDeviceId: printerLocalState.id,
    onDeviceConnect: (device) => {
      // NOTE: we do not ignore a connect event while printing because the device reference can change and we need to keep it up to date
      initDevice(device);
    },
    onDeviceDisconnect: () => {
      if (isPrintingRef.current) {
        return;
      }

      dispatch({ type: "disconnect" });
    },
  });

  // Context methods

  const setup = useCallback(() => {
    return requestDevice();
  }, [requestDevice]);

  const forget = useCallback(async () => {
    try {
      setPrinterLocalState({ ...printerLocalState, id: undefined });
      dispatch({ type: "forget" });
      await printerState.device?.forget();
    } catch (err) {
      dispatch({ type: "forget-error" });
    }
  }, [printerLocalState, printerState.device, setPrinterLocalState]);

  const printTicket = useCallback(
    async (type: TicketType, order: Order, { isTestTicket = false } = {}) => {
      if (!printerState.device) {
        throw new Error("Device not connected");
      }

      if (isPrintingRef.current) {
        return;
      }

      isPrintingRef.current = true;
      const printer = new Printer(printerState.device);
      await printer.connect();

      const ticketGenerator = new OrderTicketGenerator(
        ticketTranslator,
        storeInfo,
        settings,
      );

      const commands = await ticketGenerator.getPrinterCommands(type, order, {
        isTestTicket,
      });

      await printer.sendCommands(commands);

      // NOTE: workaround to avoid micro-disconnections while printing
      setTimeout(() => {
        isPrintingRef.current = false;
      }, 5000);
    },
    [printerState.device, ticketTranslator, storeInfo, settings],
  );

  const saveSettings = useCallback(
    (settings: PrinterSettings) => {
      setPrinterLocalState({ ...printerLocalState, settings });
    },
    [printerLocalState, setPrinterLocalState],
  );

  const contextValue = useMemo(
    () => ({
      ...printerState,
      browserNotSupported,
      osNotSupported,
      setup,
      forget,
      printTicket,
      saveSettings,
      settings,
    }),
    [forget, printTicket, printerState, saveSettings, settings, setup],
  );

  return (
    <PrinterContext.Provider value={contextValue}>
      {children}
    </PrinterContext.Provider>
  );
}

function usePrinterContext() {
  const ctx = useContext(PrinterContext);

  if (ctx === undefined) {
    throw new Error(
      "usePrinterContext can only be used in a PrinterProvider tree",
    );
  }

  return ctx;
}

export { PrinterProvider, usePrinterContext };
