import { createTranslator } from "next-intl";

import { type PRINTER_COLUMNS } from "./constants";
import type PrinterError from "./PrinterError";

export enum DeviceClassCode {
  PRINTER = 7,
}

export enum DeviceType {
  USB = 1,
  BLUETOOTH = 2,
}

// NOTE: This function is only used to generate the type for the translator
function getTranslator(locale: string) {
  return createTranslator({
    locale,
    namespace: "providers.printer-provider.ticket",
  });
}

export type TicketTranslator = ReturnType<typeof getTranslator>;

export type PrinterColumns = typeof PRINTER_COLUMNS;

export interface PrinterSettings {
  allowStyles: boolean;
  allowSpecialChars: boolean;
  selectedColumns: PrinterColumns[number];
}

export interface PrinterSetupResult {
  status: "success" | "failed" | "cancelled";
  error?: PrinterError;
  device?: USBDevice;
}

export type TicketType = "exchange" | "purchase";
