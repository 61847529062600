import { type DeviceClassCode } from "./types";

export const requestUSBDevice = (classCode: DeviceClassCode) => {
  return navigator.usb?.requestDevice({
    filters: [
      {
        classCode,
      },
    ],
  });
};

export const findDevice = async (deviceId: string) => {
  const devices = await navigator.usb?.getDevices();

  return devices.find((d) => getDeviceId(d) === deviceId);
};

export const getDeviceId = (device: USBDevice) => {
  const { productName, manufacturerName, serialNumber } = device;
  return `${productName}-${manufacturerName}-${serialNumber}`;
};

export const isSameDevice = (device1: USBDevice, device2: USBDevice) => {
  return getDeviceId(device1) === getDeviceId(device2);
};

export const getDeviceLabel = (device: USBDevice) => {
  const { productName, manufacturerName } = device;

  return `${productName} - ${manufacturerName}`;
};

const getNavigator = () => {
  return typeof window === "undefined" ? undefined : navigator;
};

export const isBrowserSupported = () => {
  return !!getNavigator()?.usb;
};

export const isOSSupported = () => {
  const navigator = getNavigator();

  if (!navigator) {
    return false;
  }

  const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent),
    isMac = navigator.userAgent.includes("Macintosh"),
    isTouch = navigator.maxTouchPoints >= 1;

  return !isIos && !(isMac && isTouch);
};
