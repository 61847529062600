"use client";

import ConfirmModal, { type ConfirmModalProps } from "./ConfirmModal";

export type AlertModalProps = Omit<
  ConfirmModalProps,
  "dismissButtonText" | "onDismiss"
>;

const AlertModal = (props: Readonly<AlertModalProps>) => {
  return <ConfirmModal {...props} />;
};

export default AlertModal;
