"use client";

import { useSearchParams } from "next/navigation";
import { signOut } from "next-auth/react";
import { useTranslations } from "next-intl";

import logger from "@offline/logger/logger";

import AlertModal from "~/components/AlertModal";
import { env } from "~/env.mjs";
import { useRouter } from "~/navigation";
import { useStoreInfo } from "~/providers/StoreInfoProvider";

const { NEXT_PUBLIC_INSTI_BASE_URL_BR, NEXT_PUBLIC_INSTI_BASE_URL_ES } = env;

export default function InvalidStoreCheck() {
  const t = useTranslations("layout.invalidStoreCheck");
  const router = useRouter();
  const searchParams = useSearchParams();
  const targetStoreId = searchParams.get("storeId");
  const storeInfo = useStoreInfo();
  const isInvalidStore =
    !!targetStoreId && targetStoreId !== storeInfo.id.toString();

  const handleConfirm = async () => {
    const baseUrl =
      storeInfo.country === "BR"
        ? NEXT_PUBLIC_INSTI_BASE_URL_BR
        : NEXT_PUBLIC_INSTI_BASE_URL_ES;

    logger.warn(
      {
        storeId: storeInfo.id.toString(),
        targetStoreId,
      },
      "Invalid store credentials after oauth signin",
    );

    await signOut();

    router.replace(`${baseUrl}/logout`);
  };

  return isInvalidStore ? (
    <AlertModal
      open={true}
      title={t("title")}
      description={t("description")}
      confirmButtonText={t("actionButton")}
      onConfirm={handleConfirm}
    />
  ) : null;
}
