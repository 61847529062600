import { Cut, Printer as EscPosPrinter, Model, WebUSB } from "escpos-buffer";
import Epson from "escpos-buffer/dist/profile/Epson";

import { profileInfo } from "./constants";

export default class Printer {
  private readonly device: USBDevice;

  private _connection?: WebUSB;

  private _printer?: EscPosPrinter;

  constructor(device: USBDevice) {
    this.device = device;
  }

  async connect() {
    const profile = new Epson(profileInfo);
    const model = new Model(profile);
    const connection = new WebUSB(this.device);
    this._printer = await EscPosPrinter.CONNECT(model, connection);
    this._connection = connection;
  }

  async sendCommands(buffer: Buffer) {
    await this.connection.write(buffer);
  }

  get printer() {
    if (!this._printer) {
      throw new Error("Printer not connected");
    }

    return this._printer;
  }

  get connection() {
    if (!this._connection) {
      throw new Error("Connection not connected");
    }

    return this._connection;
  }

  async finish() {
    await this.printer.cutter(Cut.Full);
    await this.printer.close();
  }
}
