const DEFAULT_PRINTER_FONT = "Font A"; // Normal font
const DEFAULT_CODE_PAGE = "cp850"; // Multilingual (Latin-1)
export const PRINTER_COLUMNS = [32, 40, 42, 44, 46, 48, 56, 64] as const;

export enum Spacing {
  SPACE_BETWEEN = 0,
  LEFT_PADDING = 1,
}

export const profileInfo = {
  brand: "Tiendanube",
  profile: "epson",
  model: "Generic",
  columns: 32,
  codepage: DEFAULT_CODE_PAGE,
  // Font by column
  fonts: PRINTER_COLUMNS.map((columns) => ({
    name: DEFAULT_PRINTER_FONT,
    columns,
  })),
  codepages: [
    {
      code: DEFAULT_CODE_PAGE,
      command: "\x1bt\x02",
    },
  ],
};
