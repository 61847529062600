import { useEffect } from "react";

import { getDeviceId } from "./helpers";

interface Props {
  currentDeviceId?: string;
  onDeviceConnect: (device: USBDevice) => void;
  onDeviceDisconnect: (device: USBDevice) => void;
}

export default function useUSBEvents({
  currentDeviceId,
  onDeviceConnect,
  onDeviceDisconnect,
}: Props) {
  useEffect(() => {
    const handleConnect = (e: USBConnectionEvent) => {
      if (currentDeviceId === getDeviceId(e.device)) {
        onDeviceConnect(e.device);
      }
    };

    const handleDisconnect = (e: USBConnectionEvent) => {
      if (currentDeviceId === getDeviceId(e.device)) {
        onDeviceDisconnect(e.device);
      }
    };

    navigator.usb?.addEventListener("connect", handleConnect);
    navigator.usb?.addEventListener("disconnect", handleDisconnect);

    return () => {
      navigator.usb?.removeEventListener("connect", handleConnect);
      navigator.usb?.removeEventListener("disconnect", handleDisconnect);
    };
  }, [currentDeviceId, onDeviceConnect, onDeviceDisconnect]);
}
