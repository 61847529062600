"use client";

import React, { createContext, useContext } from "react";
import type { ReactNode } from "react";
import ms from "ms";

import { FullscreenSpinner } from "~/components";
import {
  FullscreenBlockedSubscriptionError,
  FullscreenStoreInfoError,
} from "~/hocs/withAccessControlAndStoreInfo/components";
import { usePathname } from "~/navigation";
import { useStoreInfo } from "~/providers/StoreInfoProvider";
import { appTRPC } from "~/providers/TRPCProvider";
import { type PlanSubscriptionSummary } from "~/types";

const SubscriptionSummaryContext = createContext<
  PlanSubscriptionSummary | undefined
>(undefined);

const useSubscriptionSummary = () => {
  const ctx = useContext(SubscriptionSummaryContext);

  if (!ctx) {
    throw new Error("Missing subscription summary");
  }

  return ctx;
};

interface Props {
  children: ReactNode;
}

function SubscriptionSummaryProvider({ children }: Readonly<Props>) {
  const storeInfo = useStoreInfo();
  const pathname = usePathname();

  const { data, isPending, isError } =
    appTRPC.planSubscriptions.getSummary.useQuery(undefined, {
      gcTime: Infinity,
      staleTime: ms("5 minutes"),
    });

  if (isPending) {
    return <FullscreenSpinner />;
  }

  if (isError && !data) {
    return <FullscreenStoreInfoError />;
  }

  const { subscription } = data;
  const { country } = storeInfo;

  if (data.status === "BLOCKED" && !pathname.startsWith("/account")) {
    const { category, planPrices } = subscription?.plan ?? {};

    const planPricesIds = planPrices?.map(({ id }) => id) || [];
    const isFreeTrial = category === "advanced-free-trial";

    return (
      <FullscreenBlockedSubscriptionError
        planPricesIds={planPricesIds}
        isFreeTrial={isFreeTrial}
        country={country}
      />
    );
  }

  return (
    <SubscriptionSummaryContext.Provider value={data}>
      {children}
    </SubscriptionSummaryContext.Provider>
  );
}

export { SubscriptionSummaryProvider, useSubscriptionSummary };
