"use client";

import { type PropsWithChildren } from "react";

import FullscreenConnectivityError from "~/hocs/withAccessControlAndStoreInfo/components/FullscreenConnectivityError";
import { useIsOnline } from "~/hooks";

function ConnectivityWrapper({ children }: Readonly<PropsWithChildren>) {
  const isOnline = useIsOnline();

  if (!isOnline) {
    return <FullscreenConnectivityError />;
  }

  return children;
}

export default ConnectivityWrapper;
