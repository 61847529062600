import { useEffect, useRef, useState } from "react";

import { getDeviceId } from "./helpers";

export default function useInitialDevice(
  deviceId: string | undefined,
  onDeviceFound: (initialDevice: USBDevice) => void,
) {
  const [devices, setDevices] = useState<USBDevice[] | undefined>();
  const initialized = useRef(false);

  useEffect(() => {
    if (devices) {
      return;
    }

    navigator.usb?.getDevices().then((devices) => setDevices(devices));
  });

  useEffect(() => {
    if (!deviceId || !devices || initialized.current) {
      return;
    }

    initialized.current = true;

    const device = devices.find((d) => getDeviceId(d) === deviceId);

    if (device) {
      onDeviceFound(device);
    }
  }, [deviceId, devices, onDeviceFound]);
}
